import React, { useEffect, useState } from "react";
import type { MenuProps, TableColumnsType, TableProps } from "antd";
import { Input, Select, Switch } from "antd";
import { Button } from "react-bootstrap";
import { Box, Modal } from "@mui/material";
import { Drawer, Dropdown, Space, Table } from "antd";
import axios from "axios";
import { API_URL } from "../../Api/Config";
import { IoClose } from "react-icons/io5";
import { Button as ButtonM } from "@mui/material";
import Wave from "react-wavify";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/es/mentions";

const label = { inputProps: { "aria-label": "Size switch demo" } };

interface DataType {
  key: React.Key;
  productid: string;
  productname: string;
  prize: string;
  performance: string;
  offer: string;
  limitedstock: string;
  stock: string;
  action: string;
  inStock: boolean;
  variants: [];
}



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SmartPhone = () => {
  const [selectedValue1, setSelectedValue1] = useState("Today");
  const [selectedValue2, setSelectedValue2] = useState("Today");
  const [open, setOpen] = useState(false);
  const [openPerform, setOpenPerform] = useState(false);
  const [opens, setOpens] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [boughtdrawer, setboughtDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);
  const [filteredData, setFilteredData] = useState<DataType[]>([]);
  const [filters, setFilters] = useState({
    productname: "",
    productid: "",
    stock: "",
  });
  const [stockid, setStockId] = useState("");
  const [variantData, setVariantData] = useState<any[]>([]);
  const [boughtTogether, setBoughtTogether] = useState<any[]>([]);

  const [offer, setOffer] = useState({
    currentOffer: "",
    newOffer: "",
  });


  const navigate = useNavigate();

  const [boughtTogethervalues, setboughtTogethervalues] = useState<any[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/products`);
        const dta = response.data.map((val: any) => {
          return val._id;
        });

        setboughtTogethervalues(dta);
      } catch (error) {
        console.error("Error fetching products", error);
        setboughtTogethervalues([]);
      }
    };
    fetchProducts();
  }, []);

  const handleboughtTogetherChange = (value: string) => {
    setBoughtTogether((prev) => {
      if (!prev.includes(value)) {
        return [...prev, value];
      }
      return prev;
    });
    console.log("Updated Bought Together Array:", boughtTogether);
  };


  // const updateOffer = async (event: React.MouseEvent<HTMLButtonElement>) => {
  //   const updtae = async (productId: String) => {
  //     try {
  //       await axios.patch(`${API_URL}/api/products/${productId}`, {
  //         currentOffer: offer.currentOffer,
  //         newOffer: offer.newOffer,
  //       });
  //       setOpens(false);
  //       fetchData();
  //     } catch (error) {
  //       console.error("Error updating Offer:", error);
  //     }
  //   };
  // };
  const week: MenuProps["items"] = [
    {
      label: "Today",
      key: "0",
      onClick: () => setSelectedValue2("Today"),
    },
    {
      label: "Week",
      key: "1",
      onClick: () => setSelectedValue2("week"),
    },
    {
      label: "Month",
      key: "2",
      onClick: () => setSelectedValue2("Month"),
    },
  ];
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const items: MenuProps["items"] = [
    {
      label: "Today",
      key: "0",
      onClick: () => setSelectedValue1("Today"),
    },
    {
      label: "Last Week",
      key: "1",
      onClick: () => setSelectedValue1("Last Week"),
    },
    {
      label: "Last Month",
      key: "2",
      onClick: () => setSelectedValue1("Last Month"),
    },
  ];

  const updateBoughtTogether = async (dta: any) => {
    try {
      await axios.patch(
        `${API_URL}/api/products/boughtTogether`,
        { productId: dta.productid, boughtTogether: dta.boughtTogether }      );
      setboughtDrawer(false);
      fetchData();
    } catch (error) {
      console.error("Error updating Bought Together:", error);
    }
  };

  
  const [performData, setPerformData] = useState({
    lastMonth: {
      canceledCount: 0,
      completedCount: 0,
      completedPercentage: "",
      canceledPercentage: ""
    },
    lastWeek: {
      canceledCount: 0,
      completedCount: 0,
      completedPercentage: "",
      canceledPercentage: ""
    },
    today: {
      canceledCount: 0,
      completedCount: 0,
      completedPercentage: "",
      canceledPercentage: ""
    }
  })

  console.log(performData);
  

  const showDrawersPerform = async (data: any) => {
    console.log(data.productid);
    try {
      const dd = await axios.get(`${API_URL}/api/report/order-status/${data.productid}`)
      console.log(dd.data);
      setPerformData(dd.data)
      setOpenPerform(true)
    }
    catch (error) {
      console.log(error);

    }
  }
  const onClosePerform = () => setOpenPerform(false);
  const showboughtDrawers = () => setboughtDrawer(true);
  const closeboughtDrawers = () => setboughtDrawer(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/products/smart-phone`);
      const products = response.data.products;

      // Ensure inStock is boolean
      const formattedData = products.map((product: any, index: number) => ({
        sno: index + 1,
        key: product._id,
        productid: product._id,
        productname: product.description.brand + " " + product.product,
        prize: product.variants[0]?.price || "-",
        // performance: performData.today.completedPercentage || "-",
        offer: product.variants[0]?.discount || "-",
        inStock: product.inStock || "true",
        action: "",
        variants: product.variants,
      }));

      setData(formattedData);
      setFilteredData(formattedData.reverse());
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const applyFilters = () => {
    const { productname, productid, stock } = filters;

    const filtered = data.filter((item) => {
      const matchesStock =
        stock === "in stock"
          ? item.inStock === true
          : stock === "out of stock"
            ? item.inStock === false
            : true;

      return (
        (productname
          ? item.productname.toLowerCase().includes(productname.toLowerCase())
          : true) &&
        (productid ? item.productid.includes(productid) : true) &&
        matchesStock
      );
    });

    setFilteredData(filtered.reverse());
  };

  useEffect(() => {
    applyFilters();
  }, [filters]);

  const handleStockOpen = (data: any) => {
    setStockId(data.key);
    setVariantData(data.variants);
    setOpen(true);
  };
  // const [productDetails, setProductDetails] = useState<any>(null);

  // const showDrawers = async (productId: string) => {

  //   try {
  //     const response = await axios.get(`${API_URL}/api/products/${productId}`);
  //     setProductDetails(response.data.product);
  //     setOpens(true);
  //   } catch (error) {
  //     console.error("Error fetching product details:", error);
  //   }
  // };
  // const onCloses = () => {
  //   setOpens(false);
  //   setProductDetails(null);
  // };

  const handleEditProduct = (productid: string) => {
    console.log(productid);
    navigate(`/editproducts/${productid}`);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Images",
      dataIndex: "variants",
      render: (variants) => {
        // Ensure that 'text' has the correct structure and the image URL is valid
        const imageUrl = variants?.[0].images?.[0];
        return (
          <div>
            {/* Render image if the URL is available */}
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="product"
                style={{ width: "50px", height: "50px", objectFit: "cover" }}
              />
            ) : (
              <span>No image</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Product id",
      dataIndex: "productid",
      render: (productid: string) => <div>{productid.slice(-5)}</div>,
    },
    {
      title: "Product name",
      dataIndex: "productname",
    },
    {
      title: "Prize",
      dataIndex: "prize",
      render: (prize: string) => <div>&#8377;{prize}</div>,
    },
    {
      title: "Performance",
      dataIndex: "performance",
      render: (performance: string, record: any) => (
        <>
          <div className="d-flex gap-1">
            <div className="icons-div" onClick={() => showDrawersPerform(record)}>
              <i  style={{marginLeft:"30px"}} className="fi fi-rs-tachometer-alt-slow icon-table"></i>
            </div>
            <div> {performance}</div>

            <Drawer open={openPerform}>
              <div>
                <div className="d-flex justify-content-between">
                  <p>Product</p>
                  <p
                    onClick={onClosePerform}
                    className="head-txt"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fi fi-rr-cross-small"></i>
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <h2 className="head-txt">Performance</h2>

                  <div>
                    <Dropdown menu={{ items }} trigger={["click"]}>
                      <div onClick={(e) => e.preventDefault()}>
                        <Space>
                          {selectedValue1}{" "}
                          <i className="fi fi-rs-angle-small-down"></i>
                        </Space>
                      </div>
                    </Dropdown>
                  </div>
                </div>

                <p>Product sold</p>

                <div className="d-flex justify-content-center w-100">
                  <div className="circle-container">
                    <Wave
                      fill="#000" // Set the fill color of the wave
                      paused={false}
                      className="waves"
                      options={{
                        height: 70, // Height of the wave
                        amplitude: 20, // Amplitude of the wave
                        speed: 0.15, // Speed of the wave animation
                        points: 4, // Number of points in the wave
                      }}
                    />
                    <div className="content">
                      <p className="content-1">{selectedValue1 === "Today" ? performData.today.completedCount : selectedValue1 === "Last Week" ? performData.lastWeek.completedCount : performData.lastMonth.completedCount} Orders</p>
                      <p className="content-2">{selectedValue1 === "Today" ? performData.today.completedPercentage : selectedValue1 === "Last Week" ? performData.lastWeek.completedPercentage : performData.lastMonth.completedPercentage}</p>
                    </div>
                  </div>
                </div>

                <p>Product Canceled</p>

                <div className="d-flex justify-content-center w-100">
                  <div className="circle-container">
                    <Wave
                      fill="#000"
                      paused={false}
                      className="waves"
                      options={{
                        height: 70,
                        amplitude: 20,
                        speed: 0.15,
                        points: 4,
                      }}
                    />
                    <div className="content">
                      <p className="content-1">{selectedValue1 === "Today" ? performData.today.canceledCount : selectedValue1 === "Last Week" ? performData.lastWeek.canceledCount : performData.lastMonth.canceledCount} Orders</p>
                      <p className="content-2">{selectedValue1 === "Today" ? performData.today.canceledPercentage : selectedValue1 === "Last Week" ? performData.lastWeek.canceledPercentage : performData.lastMonth.canceledPercentage}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </>
      ),
    },
    // {
    //   title: "Offer",
    //   dataIndex: "offer",
    //   render: (offer: string, record: any) => (
    //     <>
    //       <div className="d-flex gap-1">
    //         <div
    //           className="icons-div"
    //           onClick={() => showDrawers(record.productid)}
    //         >
    //           <i className="fi fi-rr-badge-percent icon-table"></i>
    //         </div>
    //         <div> {offer}</div>

    //         <Drawer open={opens} onClose={onCloses}>
    //           <div>
    //             <div className="d-flex justify-content-between">
    //               <p>Product</p>
    //               <p onClick={onCloses} className="head-txt">
    //                 <i className="fi fi-rr-cross-small"></i>
    //               </p>
    //             </div>

    //             <h2 className="head-txt">Offers</h2>

    //             {productDetails && (
    //               <div className="d-flex justify-content-between mt-4">
    //                 <div className="img-drawer">
    //                   <img
    //                     src={productDetails.image || "/assets/demo.png"}
    //                     className="img-fluid"
    //                     alt="img"
    //                   />
    //                 </div>
    //                 <div style={{ width: "40%" }}>
    //                   <p style={{ fontSize: "14px" }}>
    //                     {productDetails.name || "Product Name"}
    //                   </p>
    //                 </div>
    //                 <div>
    //                   <p style={{ fontSize: "13px", marginBottom: "5px" }}>
    //                     &#8377;{productDetails.price || "0.00"}
    //                   </p>
    //                   <p
    //                     style={{
    //                       fontSize: "13px",
    //                       marginBottom: "0px",
    //                       color: "#6c6c6c",
    //                     }}
    //                   >
    //                     M.R.P&#8377;{productDetails.mrp || "0.00"}
    //                   </p>
    //                 </div>
    //               </div>
    //             )}

    //             <div className="mt-3 d-flex justify-content-between">
    //               <div>
    //                 <Switch
    //                   defaultChecked={productDetails?.offerActive || false}
    //                   size="small"
    //                 />
    //               </div>
    //               <div style={{ cursor: "pointer" }}>
    //                 <Dropdown menu={{ items: week }} trigger={["click"]}>
    //                   <div onClick={(e) => e.preventDefault()}>
    //                     <Space>
    //                       {selectedValue2}{" "}
    //                       <i className="fi fi-rs-angle-small-down"></i>
    //                     </Space>
    //                   </div>
    //                 </Dropdown>
    //               </div>
    //             </div>

    //             <div className="offers-inputs">
    //               <div>
    //                 <label className="brand-label-s">Current</label>
    //                 <br />
    //                 <Input
    //                   className="select-brand-o"
    //                   suffix={<i className="fi fi-rr-percentage per"></i>}
    //                   value={productDetails?.currentOffer || ""}
    //                   readOnly
    //                 />
    //               </div>
    //               <div>
    //                 <label className="brand-label-s">New</label>
    //                 <br />
    //                 <Input
    //                   className="select-brand-o"
    //                   suffix={<i className="fi fi-rr-percentage per"></i>}
    //                   onChange={(e) =>
    //                     setOffer((prev) => ({
    //                       ...prev,
    //                       newOffer: e.target.value,
    //                     }))
    //                   }
    //                 />
    //               </div>
    //             </div>

    //             <div className="addnew-btn-combine-b">
    //               <div>
    //                 <Button className="btn-2" onClick={onCloses}>
    //                   Cancel
    //                 </Button>
    //               </div>

    //               <div>
    //                 <Button className="btn-1" onClick={updateOffer}>
    //                   Save
    //                 </Button>
    //               </div>
    //             </div>
    //           </div>
    //         </Drawer>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Bought",
      dataIndex: "bought",
      render: (text: any, record: any) => (
        <>
          <div className="d-flex gap">
            <div
              className="icons-div"
              onClick={showboughtDrawers}
              style={{ cursor: "pointer" }}
            >
              <i style={{marginLeft:"10px"}} className="fi fi-rs-shopping-bag-add"></i>
            </div>

            <Drawer
              open={boughtdrawer}
              onClose={closeboughtDrawers}
              title="Bought Together"
            >
              <div>
                {[1, 2, 3].map((item) => (
                  <div className="mt-3" key={item}>
                    <label className="brand-label">
                      Product Name & Id {item}
                    </label>
                    <br />
                    <Select
                      onChange={handleboughtTogetherChange}
                      className="select-brand-b"
                      suffixIcon={<i className="fi fi-rs-caret-down down"></i>}
                      placeholder="Select a product"
                    >
                      {boughtTogethervalues.map((product: any) => (
                        <Option key={product} value={product}>
                          (ID: {product})
                        </Option>
                      ))}
                    </Select>
                  </div>
                ))}
              </div>

              <div className="addnew-btn-combine-b">
                <div>
                  <Button className="btn-2" onClick={closeboughtDrawers}>
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    className="btn-1"
                    onClick={() => updateBoughtTogether(record)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Drawer>
          </div>
        </>
      ),
    },
    {
      title: "Stock",
      dataIndex: "inStock",
      render: (inStock: boolean, record: DataType) => (
        <span onClick={() => handleStockOpen(record)} style={{ cursor: "pointer" }}>View</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "productid",
      render: (productid) => (
        <div className="d-flex justify-content-start">
          <i
            onClick={() => handleEditProduct(productid)}
            className="fi fi-rs-pencil edit"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      ),
    },
  ];

  const handleSwitchChange = async (checked: boolean, variantid: any) => {
    console.log(checked, variantid, stockid);
    try {
      await axios.patch(
        `${API_URL}/api/products/update-stock/${stockid}/${variantid}`,
        { inStock: checked }
      );
      await fetchData();
    } catch (error: any) {
      console.log(error);
    }
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


  const rowSelection: TableProps<DataType>["rowSelection"] = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys),
  };


  const handleDeleteSelectedProducts = async () => {

    
    try {

      await Promise.all(
        selectedRowKeys.map(async (key) => {
          await axios.delete(
            `${API_URL}/api/products/${key}`
          );
        })
      );

      setData((prevData) =>
        prevData.filter((item) => !selectedRowKeys.includes(item.key))
      );
      setSelectedRowKeys([]);
      await fetchData();
    } catch (error) {
      console.error("Error deleting selected banners:", error);
    }
  };
  return (
    <>
      <section>
        <div className="select-combine">
          <div>
            <label className="brand-label">Product Name</label>
            <br />
            <Input
              className="select-brand"
              onChange={(e) =>
                handleFilterChange("productname", e.target.value)
              }
            />
          </div>

          <div>
            <label className="brand-label">Product ID</label>
            <br />
            <Input
              className="select-brand"
              onChange={(e) => handleFilterChange("productid", e.target.value)}
            />
          </div>

          <div>
            <label className="brand-label">Stock</label>
            <br />
            <Select
              className="select-brand"
              options={[
                { value: "in stock", label: "In Stock" },
                { value: "out of stock", label: "Out of Stock" },
              ]}
              onChange={(value) => handleFilterChange("stock", value)}
              allowClear
            />
          </div>

          <div className="btn-m">
            <Button
              className="btn-1"
              onClick={() =>
                setFilters({ productname: "", productid: "", stock: "" })
              }
            >
              Reset
            </Button>
          </div>
        </div>
        <div className="product-btn">
          <Button onClick={() =>handleDeleteSelectedProducts()} className="btn-1">Delete</Button>
        </div>

        <div className="pt-4">
          <Table<DataType>
            className="custom-table"
            rowSelection={{ ...rowSelection }}
            columns={columns}
            pagination={{ pageSize: 10 }}
            dataSource={filteredData}
            loading={loading}
          />
        </div>
      </section>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h3 className="varihead">Stock</h3>
              <IoClose onClick={handleClose} style={{ cursor: "pointer" }} />
            </div>
            {variantData?.map((val, i) => (
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="vari">{`${val.ramSize ? val.ramSize : ""}${val.storage ? "/" + val.storage : ""
                  }${val.colorName ? "/" + val.colorName : ""}`}</h4>
                <Switch
                  checked={val.inStock}
                  onChange={(checked) => handleSwitchChange(checked, val._id)}
                />
              </div>
            ))}
            <div className="text-center pt-3">
              <ButtonM
                variant="contained"
                className="btnsave"
                onClick={handleClose}
              >
                Save
              </ButtonM>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default SmartPhone;
