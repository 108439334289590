import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../Api/Config";

const label = { inputProps: { "aria-label": "Size switch demo" } };

const Subadmin = () => {
  const [subadmins, setSubadmins] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch subadmin details from the API
  useEffect(() => {
    const fetchSubadmins = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/subadmin`);
        setSubadmins(response.data.subadmins);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subadmins:", error);
        setLoading(false);
      }
    };

    fetchSubadmins();
  }, []);

  // Delete subadmin function
  const deleteSubadmin = async (subadminId: string) => {
    if (window.confirm("Are you sure you want to delete this subadmin?")) {
      try {
        await axios.delete(`${API_URL}/api/subadmin/${subadminId}`);
        setSubadmins((prevSubadmins) =>
          prevSubadmins.filter((subadmin) => subadmin._id !== subadminId)
        );
      } catch (error) {
        console.error("Error deleting subadmin:", error);
      }
    }
  };

  // Update subadmin function (toggle active/inactive status)
  const updateSubadminStatus = async (subadminId: string, isActive: boolean) => {
    try {
      await axios.patch(`${API_URL}/api/subadmin/${subadminId}`, {
        isActive: !isActive,
      });
      setSubadmins((prevSubadmins) =>
        prevSubadmins.map((subadmin) =>
          subadmin._id === subadminId
            ? { ...subadmin, isActive: !isActive }
            : subadmin
        )
      );
    } catch (error) {
      console.error("Error updating subadmin:", error);
    }
  };

  const navigate = useNavigate();
  
  const editSubadmin = async (subadminId: string) => {
    navigate(`/editsubadmin/${subadminId}`);
  }

  return (
    <>
      <section className="top-gap section-main" style={{ marginLeft: "18%" }}>
        <Container>
          <div>
            <h2 className="head-txt">Sub Admin</h2>

            <div className="d-flex justify-content-end">
              <div>
                <Link to="/add-subadmin">
                  <Button className="btn-1">Add New</Button>
                </Link>
              </div>
            </div>

            <div className="mt-lg-5">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="setting-data">S.No</TableCell>
                        <TableCell align="right" className="setting-data">
                          Name
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Position
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Email
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Password
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Access
                        </TableCell>
                        <TableCell align="right" className="setting-data">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subadmins.map((subadmin, index) => (
                        <TableRow
                          key={subadmin._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="data-setting"
                          >
                            <div>{index + 1}</div>
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {subadmin.name}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {subadmin.position}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            {subadmin.email}
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            -----
                          </TableCell>
                          <TableCell align="right" className="data-setting">
                            <Switch
                              {...label}
                              checked={subadmin.isActive}
                              size="small"
                              onChange={() =>
                                updateSubadminStatus(subadmin._id, subadmin.isActive)
                              }
                            />
                            {subadmin.isActive ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell className="data-setting">
                            <div className="d-flex gap-2 justify-content-center">
                              <div>
                                <i 
                                className="fi fi-rs-pencil edit"
                                onClick={() => editSubadmin(subadmin._id)}></i>
                              </div>
                              <div>
                                <i
                                  className="fi fi-rs-trash edit"
                                  onClick={() => deleteSubadmin(subadmin._id)}
                                ></i>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default Subadmin;
