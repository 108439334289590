import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Empty, Rate } from "antd";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { visitLexicalEnvironment } from "typescript";
import { IoCartSharp } from "react-icons/io5";
import { API_URL } from "../Api/Config";

const DealofDay = () => {
  const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;
  const [productData, setProductData] = useState<any[]>([]);

  const calculateTimeLeft = () => {
    const difference = deadline - Date.now();
    let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [dealData, setDealData] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);


  const getDeal = async () => {
    const token = localStorage.getItem("dashtoken");

    try {
      const res = await axios.get(`${API_URL}/api/banner/dealOfTheDay`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const dta = res.data.deals.filter(
        (val: any) => val.actionButton === true
      );
      console.log(dta);

      setDealData(dta.slice(-1));
    } catch (error: any) {
      console.log(error);
    }
  };

  const getProdctByID = async (id: any): Promise<any | null> => {
    const token = localStorage.getItem("dashtoken");

    try {
      const res = await axios.get(`${API_URL}/api/products/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error: any) {
      console.error(
        "Error fetching product:",
        error.response?.data || error.message
      );
      return null;
    }
  };


  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Cleanup the timer when the component unmounts
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      const updatedDealData = await Promise.all(
        dealData.map(async (val) => {
          const product = await getProdctByID(val.productId);
          return { ...val, product };
        })
      );
      setProductData(updatedDealData);
    };

    fetchProductData();
  }, [dealData]);

  useEffect(() => {
    getDeal();
  }, []);

  
  return (
    <>
      <section className="py-4">
        <Container>
          <div>
            <h2 className="head-txt-1 text-center w-100">
              <span>Deal</span> Of The Day
            </h2>
          </div>

          <div className="row pt-3">
            {productData.map((val, i) => {
              const hasVariants =
                val.product &&
                val.product.variants &&
                val.product.variants.length > 0;
              const now = new Date();
              const hours = now.getHours();
              const minutes = now.getMinutes();
              const seconds = now.getSeconds();
              return (
              <>
              {seconds-val.time.sec>0?
                <div key={i} className="col-lg-12 col-sm-12 col-12 p-2">
                  <div
                    style={{
                      backgroundColor: "#F6F6F6",
                      boxShadow: "0px 0px 8px 0px #00000040",
                      borderRadius: "5px",
                    }}
                    className="p-4"
                  >

                    <div className="row">
                      <div className="col-lg-6 col-6 d-flex justify-content-center align-items-center">
                        <div>
                          {hasVariants && (
                            <img
                              className="img-fluid"
                              src={`${API_URL}/public/images/${val.product.variants[0].images[0]}`}
                              style={{ width: "200px" }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-6">
                        <div className="position-relative">
                          <img
                            src="/assets/Group (9).png"
                            className="img-fluid offer-deal"
                          />
                          <h5 className="deal-title">
                            {val.productName} {`(${val.DealPrice.variant})`}
                          </h5>
                          <h5 className="deal-title">
                            {hasVariants
                              ? `(${val.product.variants[0].colorName})`
                              : ""}
                          </h5>
                          {hasVariants && (
                            <Rate
                              disabled
                              value={val.product.rating}
                              allowHalf
                              className="deal-rating"
                            />
                          )}
                          <p className="deal-para">
                            ₹{" "}
                            {isNaN(val.DealPrice.DealPrice)
                              ? "N/A"
                              : val.DealPrice.DealPrice.toFixed(2)}
                            <span className="ms-2">
                              MRP{" "}
                              <span style={{ textDecoration: "line-through" }}>
                                ₹{" "}
                                {isNaN(val.DealPrice.mrp)
                                  ? "N/A"
                                  : val.DealPrice.mrp.toFixed(2)}
                              </span>
                            </span>
                          </p>
                          <div className="countdown-container d-flex justify-content-between align-items-center pt-3">
                            <div className="countdown-segment countdown-hours">
                              <div>
                                <p className="mb-0">{hours-val.time.hr}</p>
                                <span>Hrs</span>
                              </div>
                            </div>
                            <div>:</div>
                            <div className="countdown-segment countdown-minutes">
                              <div>
                                <p className="mb-0">{minutes-val.time.min}</p>
                                <span>Min</span>
                              </div>
                            </div>
                            <div>:</div>
                            <div className="countdown-segment countdown-seconds">
                              <div>
                                <p className="mb-0">{seconds-val.time.sec}</p>
                                <span>Sec</span>
                              </div>
                            </div>
                          </div>
                          <div className="py-2">
                            <div className="d-flex justify-content-start align-items-center gap-4">
                              <Button
                                variant="primary"
                                className="buy-now"
                               
                              >
                               Buy
                              </Button>
                           
                                <Button
                                  className="cart-button"
                                  style={{ cursor: "pointer" }}
                                >
                                  <AiOutlineShoppingCart
                                  />
                                </Button>
                         
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>:<Empty description="No deal found"/>}
                </>
              );
            })}
          </div>
        </Container>
      </section>
    </>
  );
};

export default DealofDay;